<template>
  <view-item title="喷涂领料">
    <template #operation>
      <en-button type="primary" @click="operation.add.click">创建单据</en-button>
      <button-ajax v-if="form.data.id" :method="operation.save.click">保存</button-ajax>
      <button-ajax v-if="form.data.id && form.data.status?.code === 'P'" :method="operation.audit.click">
        确认{{ form.data.type?.code === 'MRQR' ? '退料' : '领料' }}
      </button-ajax>
      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="detail">单据信息</en-dropdown-item>
          <en-dropdown-item command="delete">删除</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        :ajax="{ action: 'GET /enospray/materialrequisition' }"
        :props="{ start: 'startDate', end: 'endDate' }"
        @row-click="manifest.row.click"
        @expand-click="dialog.visible = true"
      >
        <en-table-column label="领/退料单单号" prop="serialNo"></en-table-column>
        <en-table-column label="状态" prop="status.message"></en-table-column>

        <template #form="{ data }">
          <en-form-item label="单据日期">
            <en-date-picker v-model:start="data.startDate" v-model:end="data.endDate" class="w-full"></en-date-picker>
          </en-form-item>
          <en-form-item label="网点">
            <select-maintain
              v-model="data.tenantId"
              :ajax="{ action: 'GET /enospray/common/tenant', params: (params, shortName) => (params.payload = { shortName }) }"
              :props="{ label: 'shortName', value: 'id' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="领/退料单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
          <en-form-item label="制单人">
            <select-maintain
              v-model="data.preparedBy"
              :ajax="{ action: 'GET /enocloud/common/user', params: (params, name) => (params.payload = { name }) }"
              :props="{ label: 'name', value: 'name' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="配件编号">
            <en-input v-model="data.goodsSerialNo"></en-input>
          </en-form-item>
          <en-form-item label="配件名称">
            <en-input v-model="data.goodsName"></en-input>
          </en-form-item>
          <en-form-item label="备注">
            <en-input v-model="data.comment"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex gap-6">
        <span>单号：{{ form.data.serialNo }}</span>
        <en-tag>{{ form.data.status?.message }}</en-tag>
        <span>{{ form.data.preparedBy }}</span>
        <span>{{ formatDate(form.data.auditDatetime, true) }}</span>
        <span>{{ form.data.comment }}</span>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <div class="mb-5 flex items-center justify-around">
          <div class="flex items-center flex-col gap-2">
            <span>配件编号/名称</span>
            <select-maintain
              v-model="form.materialRequisitionGoods.operation.add.data.goods"
              :ajax="{ action: 'GET /enospray/common/goods', params: (params, name) => (params.payload = { name, sprayMaterial: true }) }"
              :props="{ label: 'storageGoodsSpecification.goods.serialNo', value: '' }"
              value-key="id"
              :disabled="form.disabled"
              class="w-full"
              @change="form.materialRequisitionGoods.operation.add.change"
            ></select-maintain>
          </div>

          <div class="flex items-center flex-col gap-2">
            <span>领料数量</span>
            <en-input-number
              v-model="form.materialRequisitionGoods.operation.add.data.count"
              :disabled="form.disabled"
              :precision="2"
              class="w-full"
              @keyup.enter.native="form.materialRequisitionGoods.operation.add.count.enter"
            ></en-input-number>
          </div>

          <div class="flex items-center flex-col gap-2">
            <span>配件名称</span>
            <span>{{ form.materialRequisitionGoods.operation.add.data.goods?.storageGoodsSpecification?.goods.name || '--' }}</span>
          </div>

          <div class="flex items-center flex-col gap-2">
            <span>库存数量</span>
            <span>{{ formatNumber(form.materialRequisitionGoods.operation.add.data.goods?.storageInventoryCount) || '--' }}</span>
          </div>

          <div class="flex items-center flex-col gap-2">
            <span>规格</span>
            <span>{{ form.materialRequisitionGoods.operation.add.data.goods?.storageGoodsSpecification?.name || '--' }}</span>
          </div>
        </div>

        <flex-box>
          <template #default="{ height }">
            <en-table :data="form.data.materialRequisitionGoods" :height="height">
              <en-table-column label="操作">
                <template #default="{ $index }: { $index: number }">
                  <en-button type="primary" link :disabled="form.disabled" @click="form.materialRequisitionGoods.operation.delete.click($index)">
                    删除
                  </en-button>
                </template>
              </en-table-column>
              <en-table-column label="编码" prop="goods.storageGoodsSpecification.goods.serialNo"></en-table-column>
              <en-table-column label="名称" prop="goods.storageGoodsSpecification.goods.name"></en-table-column>
              <en-table-column label="规格" prop="goods.storageGoodsSpecification.name"></en-table-column>
              <en-table-column label="数量">
                <template #default="{ row }: { row: EnosprayInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                  <en-input-number v-model="row.count" :disabled="form.disabled" :precision="2" class="w-full"></en-input-number>
                </template>
              </en-table-column>
              <en-table-column label="基本单位换算">
                <template #default="{ row }: { row: EnosprayInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                  {{ `${row.goods?.storageGoodsSpecification?.weight}${row.goods?.storageGoodsSpecification?.goods.warehouseUnit}` }}
                </template>
              </en-table-column>
              <en-table-column label="库存" prop="inventoryCount" v-if="form.data.status?.code === 'P'"></en-table-column>
              <en-table-column label="适用车型" prop="vehicleAmount"></en-table-column>
              <en-table-column label="品牌" prop="goods.storageGoodsSpecification.goods.brand"></en-table-column>
              <en-table-column label="备注">
                <template #default="{ row }: { row: EnosprayInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                  <en-input v-model="row.comment" :disabled="form.disabled"></en-input>
                </template>
              </en-table-column>
            </en-table>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <inventory-requestion-detail v-model="detail.visible" :data="form.data" @confirm="detail.confirm"></inventory-requestion-detail>

  <inventory-requestion-dialog v-model="dialog.visible"></inventory-requestion-dialog>
</template>

<script lang="ts">
import InventoryRequestionDetail from '@spray/components/inventory-requestion-detail.vue'
import InventoryRequestionDialog from '@spray/components/inventory-requestion-dialog.vue'

export default factory({
  components: { InventoryRequestionDetail, InventoryRequestionDialog },

  config: {
    children: {
      operation: {
        add: {
          async click() {
            await this.dirtyCheck('form')
            this.form.init()
            this.detail.visible = true
          }
        },
        save: {
          async click() {
            await this.form.submit()
            return this.form.get()
          }
        },
        audit: {
          async click() {
            await this.dirtyCheck('form')
            await this.form.audit()
            return this.form.get()
          }
        },
        option: {
          async command(option: string) {
            await this.dirtyCheck('form')
            switch (option) {
              case 'detail':
                this.detail.visible = true
                break
              case 'delete':
                await this.form.delete()
                this.form.get()
                break
            }
          }
        }
      },
      manifest: {
        row: {
          async click(row: EnocloudServiceDefinitions['ServiceReservationQueryDto']) {
            await this.dirtyCheck('form')
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enospray/materialrequisition/:requisitionId',
            data: 'object',
            loading: true,
            init: true,
            dirty: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'PUT /enospray/materialrequisition',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          delete: {
            action: 'DELETE /enospray/materialrequisition/:requisitionId',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          audit: {
            action: 'POST /enospray/materialrequisition/:requisitionId/audit',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'A'
          }
        },
        children: {
          materialRequisitionGoods: {
            operation: {
              add: {
                data: { goods: {}, count: 1 } as EnosprayInventoryDefinitions['MaterialRequisitionGoodsDto'],
                change(goods: EnosprayInventoryDefinitions['SprayGoodsDto']) {
                  const exist = this.form.data.materialRequisitionGoods.find((item) => item.goods?.id === goods.id)
                  if (!exist) this.form.materialRequisitionGoods.operation.add.data = { goods, count: 1 }
                },
                count: {
                  enter() {
                    if (this.form.materialRequisitionGoods.operation.add.data.goods?.id) {
                      this.form.data.materialRequisitionGoods.push({
                        goods: this.form.materialRequisitionGoods.operation.add.data.goods,
                        count: this.form.materialRequisitionGoods.operation.add.data.count
                      })

                      this.form.materialRequisitionGoods.operation.add.data = { goods: {} as EnosprayInventoryDefinitions['SprayGoodsDto'], count: 1 }
                    }
                  }
                }
              },
              delete: {
                click(index: number) {
                  this.form.data.materialRequisitionGoods.splice(index, 1)
                }
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      },
      dialog: {
        visible: false
      }
    }
  }
})
</script>
